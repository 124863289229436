<template>
  <div>
    <v-card class="mb-5 pa-2">
      <v-card-title>Filter</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">Country</v-col>
          <v-col cols="4">
            <v-select :items="filterValueList.country" v-model="filter.country"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">Operator</v-col>
          <v-col cols="4">
            <v-select :items="filterValueList.operator" v-model="filter.operator"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">GoingElectric</v-col>
          <v-col cols="4">
            <v-select :items="filterValueList.goingelectric" v-model="filter.goingelectric"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn elevation="2" color="primary" @click="filterList">Filter</v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-card-title>
        Results
        <v-spacer></v-spacer>
        <v-text-field
            v-model="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="tableHeaders"
            :search="tableSearch"
            :items="filteredLocationList"
            :items-per-page="100"
            :footer-props="{
              itemsPerPageOptions: [10,50,100,250,-1]
            }"
            @click:row="handleClickedRow"
        >
          <template v-slot:item.goingelectric="{ item }">
            <v-simple-checkbox
                v-model="item.goingelectric"
                disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.modified_on="{ item }">
            <span>{{ formatDate(item.modified_on) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <location-details-component v-model="locationDialog" :location="selectedLocation"
                                v-on:locationUpdated="handleLocationUpdated"></location-details-component>
  </div>
</template>

<script>
import LocationDetailsComponent from "@/components/LocationDetailsComponent";
import {DateTime} from "luxon";

export default {
  name: "Locations",
  components: {LocationDetailsComponent},
  data() {
    return {
      tableSearch: '',
      tableHeaders: [
        {text: 'Name', value: 'name'},
        {text: 'Modified Date', value: 'modified_on'},
        {text: 'Goingelectric', value: 'goingelectric'},
        {text: 'Country', value: 'country'},
        {text: 'Operator', value: 'operator.name'},
      ],
      filteredLocationList: [],
      locationList: [],
      locationDialog: false,
      selectedLocation: {},
      countryList: [],
      filterValueList: {
        country: [],
        operator: [],
        goingelectric: [
          {text: 'All', value: null},
          {text: 'Yes', value: true},
          {text: 'No', value: false},
        ],
      },
      filter: {
        country: null,
        operator: null,
        goingelectric: null,
      }
    }
  },
  mounted() {
    this.loadLocations();
  },
  methods: {
    loadLocations() {
      this.$http.get('/items/locations?sort=-modified_on&limit=-1&fields=id,name,modified_on,goingelectric,country,operator.id,operator.name&filter[ignored]=0')
          .then((resp) => {
            const data = resp.data.data;
            this.filter.country = null;
            this.filter.operator = null;
            this.filter.goingelectric = null;
            this.filterValueList.country = [];
            this.filterValueList.country.push({text: 'All', value: null});
            this.filterValueList.operator = [];
            this.filterValueList.operator.push({text: 'All', value: null});
            data.forEach(entry => {
              if (entry.country) {
                const countryIndex = this.filterValueList.country.findIndex(x => x.value === entry.country);
                if (countryIndex === -1) {
                  this.filterValueList.country.push({text: entry.country, value: entry.country});
                }
                const operatorIndex = this.filterValueList.operator.findIndex(x => x.value === entry.operator.id);
                if (operatorIndex === -1) {
                  this.filterValueList.operator.push({text: entry.operator.name, value: entry.operator.id});
                }
              }
            });
            this.filterValueList.country.sort((a, b) => {
              return a.value - b.value;
            });
            this.locationList = data;
            this.filteredLocationList = this.locationList;
          })
          .catch((err) => {
            console.error(err);
          })
    },
    handleClickedRow(value) {
      this.locationDialog = true;

      this.selectedLocation = value;
    },
    handleLocationUpdated(success) {
      if (success) {
        this.loadLocations();
      }
    },
    formatDate(date) {
      const format = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, {day: '2-digit', month: 'short'});
      return DateTime.fromISO(date).toLocal().toLocaleString(format);
    },
    filterList() {
      let filtered = this.locationList;
      // Country filter
      if (this.filter.country !== null) {
        filtered = filtered.filter(x => x.country === this.filter.country);
      }

      // GoingElectric filter
      if (this.filter.goingelectric !== null) {
        filtered = filtered.filter(x => x.goingelectric === this.filter.goingelectric);
      }

      // Operator filter
      if (this.filter.operator !== null) {
        filtered = filtered.filter(x => x.operator.id === this.filter.operator);
      }

      this.filteredLocationList = filtered;
    }
  }
}
</script>

<style scoped>

</style>
