<template>
  <div>
    <v-card>
      <v-card-text>
        <v-combobox
            v-model="selectedOperators"
            :items="operatorList"
            item-text="name"
            item-value="id"
            deletable-chips
            label="Selected operators"
            multiple
            chips
            append-outer-icon="mdi-autorenew"
            @click:append-outer="loadLocations"
        ></v-combobox>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        Results
        <v-spacer></v-spacer>
        <v-text-field
            v-model="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="tableHeaders"
            :search="tableSearch"
            :items="locationList"
            :items-per-page="100"
            :footer-props="{
              itemsPerPageOptions: [10,50,100,250,-1]
            }"
            @click:row="handleClickedRow"
        >
          <template v-slot:item.goingelectric="{ item }">
            <v-simple-checkbox
                v-model="item.goingelectric"
                disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.modified_on="{ item }">
            <span>{{ formatDate(item.modified_on) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <location-details-component v-model="locationDialog" :location="selectedLocation"
                                v-on:locationUpdated="handleLocationUpdated"></location-details-component>
  </div>
</template>

<script>
import LocationDetailsComponent from "@/components/LocationDetailsComponent";
import {DateTime} from "luxon";

export default {
  name: "LocationsGoingelectric",
  components: {LocationDetailsComponent},
  data() {
    return {
      tableSearch: '',
      tableHeaders: [
        {text: 'Name', value: 'name'},
        {text: 'Modified Date', value: 'modified_on'},
        {text: 'Goingelectric', value: 'goingelectric'},
        {text: 'Country', value: 'country'},
        {text: 'Operator', value: 'operator.name'},
        {text: 'Type', value: 'current_type'},
        {text: 'max. Power', value: 'max_power'},
      ],
      operatorList: [],
      selectedOperators: [],
      locationList: [],
      locationDialog: false,
      selectedLocation: {},
    }
  },
  mounted() {
    this.loadOperators();
  },
  methods: {
    loadLocations() {
      if (this.selectedOperators.length > 0) {
        const operatorIds = this.selectedOperators.map(x => x.id);
        this.$http.get('/items/locations?sort=-modified_on&limit=-1&fields=id,name,modified_on,goingelectric,country,operator.name,chargers.connectors.current_type,chargers.connectors.power_lv&filter[goingelectric]=0&filter[ignored]=0&filter[operator.id]=' + operatorIds.join(','))
            .then((resp) => {
              const data = resp.data.data;
              data.forEach(location => {
                let locationType = 'N/A';
                let locationPower = 0;
                location.chargers.forEach(charger => {
                  charger.connectors.forEach(connector => {
                    if (connector.current_type === 'dc') {
                      location['current_type'] = 'DC';
                      locationType = 'DC';
                    }
                    if (connector.current_type === 'ac' && locationType !== 'DC') {
                      locationType = 'AC';
                    }
                    if (connector.power_lv > locationPower) {
                      locationPower = connector.power_lv;
                    }
                  });
                });
                location['current_type'] = locationType;
                location['max_power'] = locationPower / 1000;
              });
              this.locationList = data;
            })
            .catch((err) => {
              console.error(err);
            })
      }
    },
    loadOperators() {
      this.$http.get('/items/operators?fields=id,name&sort=name')
          .then((resp) => {
            this.operatorList = resp.data.data;
          })
          .catch((err) => {
            console.error(err);
          })
    },
    handleClickedRow(value) {
      this.locationDialog = true;

      this.selectedLocation = value;
    },
    handleLocationUpdated(success) {
      if (success) {
        this.loadLocations();
      }
    },
    formatDate(date) {
      const format = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, {day: '2-digit', month: 'short'});
      return DateTime.fromISO(date).toLocal().toLocaleString(format);
    }
  }
}
</script>

<style scoped>

</style>
