<template>
  <div>
    <v-card>
      <v-card-text>
        <v-combobox
            v-model="selectedOperator"
            :items="operatorList"
            item-text="name"
            item-value="id"
            deletable-chips
            label="Selected operators"
            append-outer-icon="mdi-autorenew"
            @click:append-outer="loadLocations"
        ></v-combobox>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title>
      </v-card-title>
      <v-card-text v-if="detailLocations">
        <v-data-table
            :headers="headers"
            :items="detailLocations"
            @click:row="handleClickedRow"
            sort-by="distance"
            :items-per-page="itemsPerPage"
            :footer-props="{
              itemsPerPageOptions: [10,15,50,100,250,-1]
            }">
          <template v-slot:item.detail="{ item }">
            <v-simple-checkbox
                v-ripple
                v-model="item.detail"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="enableGoingElectric" color="warning" elevation="2">Set loading</v-btn>
        <v-btn @click="setGoingElectric" color="error" elevation="2">Load details</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "LoadDetails",
  data() {
    return {
      storageName: 'GPXLocations',
      loadingOverlay: false,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Loaded details', value: 'detail'},
      ],
      operatorList: [],
      detailLocations: [],
      selectedOperator: '',
      itemsPerPage: 15
    }
  },
  mounted() {

    this.loadOperators();
  },
  methods: {
    handleClickedRow(value) {
      value.goingelectric = !value.goingelectric;
    },
    loadOperators() {
      this.$http.get('/items/operators?fields=id,name&filter[fetch_enabled]=1&sort=name')
          .then((resp) => {
            this.operatorList = resp.data.data;
          })
          .catch((err) => {
            console.error(err);
          })
    },
    loadLocations() {
      this.detailLocations = [];
      if (this.selectedOperator?.id !== null) {
        return this.$http.get('/items/locations?sort=name&limit=-1&fields=id,name,modified_on,goingelectric,street,street_number,city,zip,latitude,longitude,chargers.connectors.modified_on,operator.fetch_slug&filter[goingelectric]=0&filter[ignored]=0&filter[operator.id]=' + this.selectedOperator.id)
            .then((resp) => {
              const data = resp.data.data;
              data.forEach((row) => {
                let detail = false;
                if (row.chargers && row.chargers.length > 0 && row.chargers[0].connectors?.length > 0) {
                  detail = true;
                }
                if(!detail) {
                  row.detail = false;
                  this.detailLocations.push(row);
                }
                row.detail = detail;
              });
              // this.detailLocations = data;
              // this.goToStep3();
              // this.compareLocations();
            })
            .catch((err) => {
              console.error(err);
            })
      }
    },
    enableGoingElectric() {
      const closest = this.detailLocations
          .sort((a, b) => a.name - b.name)
          // .slice(0, 100);

      closest.forEach((location) => {
        location.detail = true;
      });
    },
    async setGoingElectric() {
      // const promiseList = [];
      this.loadingOverlay = true;
      for await (const location of this.detailLocations) {
        if (location.detail) {
          await this.updateDetails(location)
          const wait = Math.floor(Math.random() * (3123 - 1333 + 1)) + 1333;
          await new Promise(resolve => setTimeout(resolve, wait));
        }
      }
      this.loadingOverlay = false;
      this.loadLocations();
    },
    async updateDetails(location) {
      return this.$http.get('/custom/creg/details?operator=' + location.operator.fetch_slug + '&location=' + location.id)
          .then((response) => {
            // console.log('finished ' + location.id)
            if (response.data.error) {
              alert(response.data.error);
            }
          })
          .catch(err => {
            console.error(err);
          })
    },
  },
}
</script>

<style scoped>

</style>
