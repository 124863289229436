<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="props.value"
          persistent
          fullscreen>
        <v-card>
          <v-toolbar
              dark
              color="primary">
            <v-btn
                icon
                dark
                @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ getHeaderName() }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="locationDetailed && locationDetailed.operator">
              <v-btn
                  v-if="locationDetailed.operator.detail_fetch_url !== null"
                  class="mr-15"
                  dark
                  text
                  color="secondary"
                  @click="updateDetails">
                Update details
              </v-btn>
              <v-btn
                  :disabled="locationDetailed.ignored || locationDetailed.goingelectric"
                  class="mr-15"
                  dark
                  text
                  color="warning"
                  @click="setIgnored">
                Add to ignored
              </v-btn>
              <v-btn
                  :disabled="locationDetailed.ignored || locationDetailed.goingelectric"
                  dark
                  text
                  color="green lighten-3"
                  @click="setGoingElectric">
                Added to GoingEletric
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-title>
            <span class="headline" v-if="locationDetailed">Location {{ locationDetailed.external_id }}</span>
            <span v-if="locationDetailed.operator_status !== ''">&nbsp;({{ locationDetailed.operator_status }})</span>
          </v-card-title>
          <v-card-text v-if="locationDetailed">
            <v-container>
              <v-row>
                <v-col>
                  <copy-textfield name="Name" :value="locationDetailed.name"></copy-textfield>
                </v-col>
                <template v-if="locationDetailed.operator">
                  <v-col>
                    <copy-textfield name="Operator Name" :value="locationDetailed.operator.name"></copy-textfield>
                  </v-col>
                  <v-col>
                    <copy-textfield name="Operator Phone"
                                    :value="locationDetailed.operator.phone_number"></copy-textfield>
                  </v-col>
                  <v-col>
                    <copy-textfield name="Operator Website" :value="locationDetailed.operator.website"></copy-textfield>
                  </v-col>
                </template>
              </v-row>
              <v-row>
                <v-col>
                  <copy-textfield name="Latitude" :value="locationDetailed.latitude"></copy-textfield>
                </v-col>
                <v-col>
                  <copy-textfield name="Longitude" :value="locationDetailed.longitude"></copy-textfield>
                </v-col>
                <v-col>
                  <copy-textfield name="Lat,Lng"
                                  :value="locationDetailed.latitude + ', ' + locationDetailed.longitude"></copy-textfield>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <copy-textfield name="Street" :value="locationDetailed.street"></copy-textfield>
                </v-col>
                <v-col>
                  <copy-textfield name="Street Number" :value="locationDetailed.street_number"></copy-textfield>
                </v-col>
                <v-col>
                  <copy-textfield name="Post Code" :value="locationDetailed.zip"></copy-textfield>
                </v-col>
                <v-col>
                  <copy-textfield name="City" :value="locationDetailed.city"></copy-textfield>
                </v-col>
                <v-col>
                  <copy-textfield name="Country" :value="locationDetailed.country"></copy-textfield>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-for="charger in locationDetailed.chargers" v-bind:key="charger.id"
                       class="elevation-5 ma-1">
                  <v-container>
                    <v-row>
                      <v-col><span class="text-subtitle-4">Charger #{{ charger.id }} </span></v-col>
                    </v-row>
                    <v-row v-if="charger.manufacturer || charger.model">
                      <v-col>
                            <span class="text-subtitle-1 font-weight-bold">
                              {{ charger.manufacturer ? charger.manufacturer + '&nbsp;': '' }}{{ charger.model }}
                            </span>

                        <template v-if="charger.firmware"><br><span class="text-subtitle-3">FW:&nbsp;{{ charger.firmware }}</span></template>
                        <template v-if="charger.serialnumber"><br><span class="text-subtitle-3">Ser. No.&nbsp;{{ charger.serialnumber }}</span></template>
                      </v-col>
                    </v-row>
                    <v-row v-if="charger.operator_status">
                      <v-col>
                            <span class="text-subtitle-1 font-weight-bold">
                              Status:&nbsp;{{ charger.operator_status }}
                            </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <copy-textfield name="Charger ID of operator" :value="charger.external_id"></copy-textfield>
                      </v-col>
                    </v-row>
                    <v-row v-for="connector in charger.connectors" v-bind:key="connector.id">
                      <v-divider></v-divider>
                      <v-container>
                        <!--                        <v-row><span class="text-button">Connector #{{ connector.id }}</span></v-row>-->
                        <v-row>
                          <v-col>
                            <span class="text-subtitle-1 font-weight-bold">
                              <template v-if="connector.current_type">
                                {{ getCurrentType(connector.current_type) }}&nbsp;&dash;&nbsp;
                              </template>
                              {{ getPlugType(connector.plug) }}&nbsp;&dash;&nbsp;
                              {{ getPowerInKw(connector.power_lv) }}&nbsp;kW
                            </span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <copy-textfield name="Connector ID of operator"
                                            :value="connector.public_id"></copy-textfield>
                          </v-col>
                        </v-row>
                        <!--                        <v-row>-->
                        <!--                          <v-col>-->
                        <!--                            <copy-textfield name="Voltage" :value="connector.voltage"></copy-textfield>-->
                        <!--                          </v-col>-->
                        <!--                        </v-row>-->
                        <!--                        <v-row>-->
                        <!--                          <v-col>-->
                        <!--                            <copy-textfield-->
                        <!--                                name="Power LV (~400V)"-->
                        <!--                                :value="getPowerInKw(connector.power_lv)"></copy-textfield>-->
                        <!--                          </v-col>-->
                        <!--                          <v-col v-if="connector.power_lv !== connector.power_hv">-->
                        <!--                            <copy-textfield-->
                        <!--                                name="Power HV (~800V)"-->
                        <!--                                :value="getPowerInKw(connector.power_hv)"></copy-textfield>-->
                        <!--                          </v-col>-->
                        <!--                          <v-col>-->
                        <!--                            <copy-textfield-->
                        <!--                                name="Current LV (~400V)"-->
                        <!--                                :value="connector.current_lv"></copy-textfield>-->
                        <!--                          </v-col>-->
                        <!--                          <v-col v-if="connector.current_lv !== connector.current_hv">-->
                        <!--                            <copy-textfield-->
                        <!--                                name="Current HV (~800V)"-->
                        <!--                                :value="connector.current_hv"></copy-textfield>-->
                        <!--                          </v-col>-->
                        <!--                        </v-row>-->
                      </v-container>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <!--            <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="props.value = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="props.value = false"
                        >
                          Save
                        </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CopyTextfield from "@/components/CopyTextfield";

export default {
  name: "LocationDetailsComponent",
  components: {CopyTextfield},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    value(updated) {
      this.props.value = updated;
    },
    'props.value'(updated) {
      this.$emit('input', updated);
    },
    location(updated) {
      if (updated.id) {
        this.loadLocation(updated.id);
      }
    }
  },
  data: () => ({
    props: {
      value: false,
    },
    locationDetailed: {}
  }),
  methods: {
    getHeaderName() {
      let name = '';
      if (this.locationDetailed) {
        name = this.locationDetailed.name;
      }
      if (this.locationDetailed.operator) {
        name += ' - ' + this.locationDetailed.operator.name;
      }
      if (this.locationDetailed.goingelectric) {
        name += ' (GoingElectric)';
      }

      return name;
    },
    loadLocation(id) {
      this.$http.get('/items/locations/' + id + '?fields=*.*.*')
          .then(response => {
            let location = response.data.data;
            // this.locationDetailed = response.data.data;
            location.chargers.sort((a, b) => {
              console.log(a.external_id)
              console.log(b.external_id)
              return a.external_id - b.external_id;
            });
            this.locationDetailed = location;
          })
          .catch(err => {
            console.error(err);
          });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    getPlugType(plugType) {
      if (plugType === 'type2_ccs') {
        return 'Combo Type 2 (CCS)';
      } else if (plugType === 'type2_plug') {
        return 'Type 2 Plug';
      } else if (plugType === 'type2_socket') {
        return 'Type 2 Socket';
      } else if (plugType === 'type1_plug') {
        return 'Type 1 Plug';
      } else if (plugType === 'type3') {
        return 'Type 3';
      } else if (plugType === 'chademo') {
        return 'CHAdeMO';
      } else if (plugType === 'schuko') {
        return 'Schuko';
      } else if (plugType === 'cee_red5') {
        return 'CEE Red 5 Pin';
      } else if (plugType === 'cee_blue3') {
        return 'CEE Blue 3 Pin';
      }
    },
    getPowerInKw(powerInW) {
      return powerInW / 1000;
    },
    getCurrentType(currentType) {
      if (currentType === 'ac') {
        return 'AC';
      } else if (currentType === 'dc') {
        return 'DC';
      }
    },
    setGoingElectric() {
      this.$http.patch('/items/locations/' + this.locationDetailed.id, {goingelectric: true})
          .then(() => {
            this.props.value = false;
            this.$emit('locationUpdated', true);
          })
          .catch(err => {
            console.error(err);
          })
    },
    setIgnored() {
      this.$http.patch('/items/locations/' + this.locationDetailed.id, {ignored: true})
          .then(() => {
            this.props.value = false;
            this.$emit('locationUpdated', true);
          })
          .catch(err => {
            console.error(err);
          })
    },
    updateDetails() {
      this.$http.get('/custom/creg/details?operator=' + this.locationDetailed.operator.fetch_slug + '&location=' + this.locationDetailed.id)
          .then((response) => {
            if (response.data.error) {
              alert(response.data.error);
            } else {
              this.locationDetailed = response.data.data;
            }
          })
          .catch(err => {
            console.error(err);
          })
    },
    closeDialog() {
      this.props.value = false;
      this.locationDetailed = {};
    }
  }
}
</script>

<style scoped>

</style>
