<template>
  <div>
    <v-card>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Operator</th>
              <th class="text-left">Percentage</th>
              <th class="text-left">GoingElectric</th>
              <th class="text-left">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="operator in combinedList"
                :key="operator.id">
              <td>{{ operator.name }}</td>
              <td>{{ Math.round((operator.ge / operator.total) * 100) }}%</td>
              <td>{{ operator.ge }}</td>
              <td>{{ operator.total }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GoingElectricOverview",
  data() {
    return {
      operatorList: [],
      combinedList: [],
    };
  },
  mounted() {
    this.loadOperators()
        .then(() => {
          this.loadLocations();
        })
  },
  methods: {
    loadOperators() {
      return this.$http.get('/items/operators?fields=id,name&filter[fetch_enabled]=1&sort=name')
          .then((resp) => {
            this.operatorList = resp.data.data;
            this.operatorList.forEach(operator => {
              operator['ge'] = 0;
              operator['total'] = 0;
            });
          })
          .catch((err) => {
            console.error(err);
          })
    },
    loadLocations() {
      return this.$http.get('/items/locations?limit=-1&fields=id,goingelectric,operator.id&filter[ignored]=0')
          .then((resp) => {
            const data = resp.data.data;
            const opList = this.operatorList;
            data.forEach(location => {
              const operator = opList.findIndex(x => x.id === location.operator.id);
              if (operator !== -1) {
                if (location.goingelectric) {
                  opList[operator]['ge']++;
                }
                opList[operator]['total']++;
              }
            });
            this.combinedList = opList;
          })
          .catch((err) => {
            console.error(err);
          })
    },
  }
}
</script>

<style scoped>

</style>
