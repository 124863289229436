<template>
  <div>
    <v-card>
      <v-card-title>Results</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="text-h6">Name</v-col>
          <v-col class="text-h6">Website</v-col>
          <v-col class="text-h6">Fetch Enabled</v-col>
          <v-col class="text-h6">Backend</v-col>
        </v-row>

        <template v-for="operator in operatorList">
          <v-row v-bind:key="operator.id">
            <v-col>{{ operator.name }}</v-col>
            <v-col>{{ operator.website }}</v-col>
            <v-col>{{ operator.fetch_enabled ? 'Yes': 'No' }}</v-col>
            <v-col>{{ operator.backend }}</v-col>
          </v-row>
        </template>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
export default {
  name: "Operators",
  data() {
    return {
      operatorList: []
    }
  },
  mounted() {
    this.loadOperators();
  },
  methods: {
    loadOperators() {
      this.$http.get('/items/operators')
          .then((resp) => {
            const data = resp.data.data;
            this.operatorList = data;
          })
          .catch((err) => {
            console.error(err);
          })
    }
  }
}
</script>

<style scoped>

</style>
