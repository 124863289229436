<template>
  <v-text-field
      :label="name"
      readonly
      dense
      append-icon="mdi-content-copy"
      @click="copyToClipboard(value)"
      @click:append="copyToClipboard(value)"
      v-model="value"
  ></v-text-field>
</template>

<script>
export default {
  name: "CopyTextfield",
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      default: '',
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  }
}
</script>

<style scoped>

</style>
