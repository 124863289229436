<template>
  <div>
    <v-btn color="red" @click="downloadSuperchargerData">
      Load SuC data
    </v-btn>
    <div>
      Total Locations: {{superchargerList.length}}<br>
      Open Locations: {{open.length}}<br>
      V2 Locations (until 150 kW): {{v2.length}}<br>
      V3 Locations (> 150 kW): {{v3.length}}<br>
      Check Locations (V2 + V3): {{v2.length + v3.length}}<br>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "LocationsMap",
  data() {
    return {
      superchargerList: [],
    };
  },
  mounted() {
    console.log('bla');


  },
  computed: {
    open() {
      return this.superchargerList.filter(x => x.status === 'OPEN')
    },
    v2() {
      return this.superchargerList.filter(x => x.status === 'OPEN' && x.powerKilowatt <=150)
    },
    v3() {
      return this.superchargerList.filter(x => x.status === 'OPEN' && x.powerKilowatt > 150)
    },
  },
  methods: {
    downloadSuperchargerData() {
      return axios.get('https://supercharge.info/service/supercharge/sites?regionId=101&length=10000')
          .then(response => {
            console.log(response)
            let data = response.data.results;
            console.log(data)
            this.superchargerList = data;
            return data;
          })
    }
  }
}
</script>

<style scoped>

</style>
