<template>
  <div>
    <v-card>
      <v-card-title>
        Results
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="tableHeaders"
            :items="history"
            :items-per-page="20"
            :show-expand="true"
            sort-by="created_on"
            sort-desc
            :footer-props="{
              itemsPerPageOptions: [10,20,50,100,250,-1]
            }">
          <template v-slot:item.created_on="{ item }">
            <span>{{ formatDate(item.created_on) }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-textarea :value="item.log" auto-grow readonly>
              </v-textarea>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
  name: "OperatorsFetchHistory",
  data() {
    return {
      tableHeaders: [
        {text: 'Operator', value: 'operator.name'},
        {text: 'Fetch date', value: 'created_on'},
        {text: 'Created locations', value: 'locations_created'},
        {text: 'Updated locations', value: 'locations_updated'},
        {text: 'Created chargers', value: 'chargers_created'},
        {text: 'Updated chargers', value: 'chargers_updated'},
        {text: 'Created connectors', value: 'connectors_created'},
        {text: 'Updated connectors', value: 'connectors_updated'},
        {text: '', value: 'data-table-expand'},
      ],
      history: [],
    }
  },
  mounted() {
    this.loadFetchHistory();
  },
  methods: {
    loadFetchHistory() {
      this.$http.get('/items/fetch_history?sort=-created_on&fields=*,operator.*')
          .then((resp) => {
            this.history = resp.data.data;
          })
          .catch((err) => {
            console.error(err);
          })
    },
    formatDate(date) {
      const format = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, {day: '2-digit', month: 'short'});
      return DateTime.fromISO(date).toLocal().toLocaleString(format);
    },
  }
}
</script>

<style scoped>

</style>
