<template>
    <div>
        <v-card class="mb-5 pa-2">
            <v-card-title>Filter</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="2">Operator</v-col>
                    <v-col cols="4">
                        <v-select :items="operatorList" v-model="filter.operator"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn elevation="2" color="primary" @click="filterList">Filter</v-btn>
            </v-card-actions>
        </v-card>
        <v-card>
            <v-card-text>
                <v-data-table
                        :headers="tableHeaders"
                        :items="locationsModified"
                        @click:row="handleClickedRow"
                        sort-by="last_changed"
                        :loading="dataLoading"
                        sort-desc
                        :items-per-page="20"
                        :footer-props="{
              itemsPerPageOptions: [10,20,50,100,250]
            }">
                    <template v-slot:item.goingelectric="{ item }">
                        <v-simple-checkbox
                                v-model="item.goingelectric"
                                disabled
                        ></v-simple-checkbox>
                    </template>
                    <template v-slot:item.last_changed="{ item }">
                        <span>{{ formatDate(item.last_changed) }}</span>
                    </template>
                    <template v-slot:item.location_modified="{ item }">
                        <span>{{ formatDate(item.location_modified) }}</span>
                    </template>
                    <template v-slot:item.charger_modified="{ item }">
                        <span>{{ formatDate(item.charger_modified) }}</span>
                    </template>
                    <template v-slot:item.connector_modified="{ item }">
                        <span>{{ formatDate(item.connector_modified) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <location-details-component v-model="locationDialog" :location="selectedLocation"></location-details-component>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import LocationDetailsComponent from "@/components/LocationDetailsComponent";

export default {
    name: "LocationsLastChanged",
    components: {LocationDetailsComponent},
    data() {
        return {
            dataLoading: true,
            tableHeaders: [
                {text: 'Operator', value: 'operator'},
                {text: 'Location', value: 'name'},
                {text: 'GE', value: 'goingelectric'},
                {text: 'Last changed', value: 'last_changed'},
                {text: 'Modified (Location)', value: 'location_modified'},
                {text: 'Modified (Charger)', value: 'charger_modified'},
                {text: 'Modified (Connector)', value: 'connector_modified'},
            ],
            locationsModified: [],
            locationDialog: false,
            selectedLocation: {},
            operatorList: [],
            filter: {
                operator: null,
            }
        };
    },
    mounted() {
        this.loadOperators();
        this.loadLocations();
    },
    methods: {
        loadLocations(operatorId = null) {
            let url = '/items/locations?fields=id,name,modified_on,goingelectric,chargers.id,chargers.modified_on,chargers.connectors.id,chargers.connectors.modified_on,operator.name&limit=1337&filter[ignored]=0&sort=-modified_on';
            if (operatorId) {
                url += '&filter[operator.id]=' + operatorId;
            }
            this.$http.get(url)
                .then((resp) => {
                    const locations = resp.data.data;
                    const modList = [];
                    locations.forEach(location => {
                        const loc = {
                            id: location.id,
                            name: location.name,
                            operator: location.operator.name,
                            goingelectric: location.goingelectric,
                            last_changed: location.modified_on,
                            location_modified: location.modified_on,
                            charger_modified: null,
                            connector_modified: null,
                        };
                        location.chargers.forEach(charger => {
                            if (loc.charger_modified === null || DateTime.fromISO(charger.modified_on) > DateTime.fromISO(loc.charger_modified)) {
                                loc.charger_modified = charger.modified_on;
                            }
                            if (DateTime.fromISO(charger.modified_on) > DateTime.fromISO(loc.last_changed)) {
                                loc.last_changed = charger.modified_on;
                            }
                            charger.connectors.forEach(connector => {
                                if (loc.connector_modified === null || DateTime.fromISO(connector.modified_on) > DateTime.fromISO(loc.connector_modified)) {
                                    loc.connector_modified = connector.modified_on;
                                }

                                if (DateTime.fromISO(connector.modified_on) > DateTime.fromISO(loc.last_changed)) {
                                    loc.last_changed = connector.modified_on;
                                }
                            });
                        });
                        modList.push(loc);
                    });
                    this.locationsModified = modList;
                    this.dataLoading = false
                })
                .catch((err) => {
                    console.error(err);
                    this.dataLoading = false
                })
        },
        loadOperators() {
            this.$http.get('/items/operators?fields=id,name&sort=name')
                .then((resp) => {
                    const data = resp.data.data;
                    // this.operatorList = data;
                    this.operatorList = [];
                    this.operatorList.push({text: 'All', value: null});
                    data.forEach(operator => {
                        this.operatorList.push({text: operator.name, value: operator.id});
                    });
                })
                .catch((err) => {
                    console.error(err);
                })
        },
        handleClickedRow(value) {
            this.locationDialog = true;

            this.selectedLocation = value;
        },
        formatDate(date) {
            const format = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, {day: '2-digit', month: 'short'});
            if (date === '' || date == null) {
                return '';
            }
            return DateTime.fromISO(date).toLocal().toLocaleString(format);
        },
        filterList() {
            this.loadLocations(this.filter.operator);
        }
    }
}
</script>

<style scoped>

</style>
