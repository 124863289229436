<template>
  <div>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="text-h6">Name</v-col>
          <v-col class="text-h6">&nbsp;</v-col>
          <v-col class="text-h6">last Updated</v-col>
          <v-col class="text-h6">Results</v-col>
        </v-row>
        <v-row>

          <v-col>All</v-col>
          <v-col>
            <template>
              <v-btn icon @click="fetchData('all')">
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </template>
          </v-col>
          <v-col>&nbsp;</v-col>
          <v-col>&nbsp;</v-col>
        </v-row>

        <template v-for="operator in operatorList">
          <v-row v-bind:key="operator.id">
            <v-col>{{ operator.name }}</v-col>
            <v-col>
              <template v-if="isRunning(operator.fetch_slug)">
                <v-btn icon @click="fetchData(operator.fetch_slug)">
                  <v-icon>mdi-autorenew</v-icon>
                </v-btn>
              </template>
              <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-col>
            <v-col>{{ formatDate(operator.last_fetch) }}</v-col>
            <v-col>
              <template v-if="hasResults(operator.fetch_slug)">
                <div>
                  <p v-if="getResult(operator.fetch_slug)['locations']['created'] > 0 || getResult(operator.fetch_slug)['locations']['updated'] > 0">
                    Locations:
                    <span v-if="getResult(operator.fetch_slug)['locations']['created'] > 0">
                      Created {{ getResult(operator.fetch_slug)['locations']['created'] }}
                    </span>
                    <span v-if="getResult(operator.fetch_slug)['locations']['updated'] > 0">
                      Updated {{ getResult(operator.fetch_slug)['locations']['updated'] }}
                    </span>
                  </p>
                  <p v-if="getResult(operator.fetch_slug)['chargers']['created'] > 0 || getResult(operator.fetch_slug)['chargers']['updated'] > 0">
                    Chargers:
                    <span v-if="getResult(operator.fetch_slug)['chargers']['created'] > 0">
                      Created {{ getResult(operator.fetch_slug)['chargers']['created'] }}
                    </span>
                    <span v-if="getResult(operator.fetch_slug)['chargers']['updated'] > 0">
                      Updated {{ getResult(operator.fetch_slug)['chargers']['updated'] }}
                    </span>
                  </p>
                  <p v-if="getResult(operator.fetch_slug)['connectors']['created'] > 0 || getResult(operator.fetch_slug)['connectors']['updated'] > 0">
                    Connectors:
                    <span v-if="getResult(operator.fetch_slug)['connectors']['created'] > 0">
                      Created {{ getResult(operator.fetch_slug)['connectors']['created'] }}
                    </span>
                    <span v-if="getResult(operator.fetch_slug)['connectors']['updated'] > 0">
                      Updated {{ getResult(operator.fetch_slug)['connectors']['updated'] }}
                    </span>
                  </p>
                  <p v-if="getResult(operator.fetch_slug)['log'].length > 0">
                    <span v-for="(entry, index) in getResult(operator.fetch_slug)['log']" v-bind:key="index">
                    <span class="font-weight-bold">Entry&nbsp;{{ (index + 1) }}&nbsp;&dash;&nbsp;</span>{{ entry }}<br>
                    </span><br>
                  </p>
                  <p v-else>No changes</p>
                </div>
              </template>
              <template v-else>
                &nbsp;
              </template>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
  name: "Fetch",
  data() {
    return {
      operatorList: [],
      fetchRunning: [],
      operatorResults: [],
    }
  },
  mounted() {
    this.loadOperators();
  },
  methods: {
    loadOperators() {
      this.$http.get('/items/operators?filter[fetch_enabled]=1&sort=name')
          .then((resp) => {
            const data = resp.data.data;
            const fetchOperators = [];
            data.forEach(entry => {
              if (entry.fetch_enabled && entry.fetch_slug !== '') {
                fetchOperators.push(entry);
              }
            });
            this.operatorList = fetchOperators;
          })
          .catch((err) => {
            console.error(err);
          })
    },
    updateOperator(operatorId) {
      this.$http.get('/items/operators/' + operatorId)
          .then(response => {
            const operatorIndex = this.operatorList.findIndex(x => x.id === operatorId);
            if (operatorIndex !== -1) {
              this.operatorList[operatorIndex]['last_fetch'] = response.data.data.last_fetch;
            }
          })
          .catch(err => {
            console.error(err);
          });
    },
    fetchData(operator) {
      if (operator === 'all') {
        this.operatorList.forEach(entry => {
          this.fetchRunning.push(entry.fetch_slug);
          this.getData(entry.fetch_slug);
        });
      } else {
        this.fetchRunning.push(operator);
        this.getData(operator);
      }
    },
    getData(operator) {
      const operatorIndex = this.operatorResults.findIndex(x => x.name === operator);
      if (operatorIndex !== -1) {
        // Remove previous results
        this.operatorResults.splice(operatorIndex, 1);
      }
      this.$http.get('/custom/creg/fetch?operator=' + operator)
          .then(response => {
            const data = response.data;
            data.forEach(result => {
              this.operatorResults.push(result);
            });
            if (operator === 'all') {
              // this.fetchRunning = [];
            } else {
              this.fetchRunning.splice(this.fetchRunning.findIndex(x => x === operator), 1);
              const operatorKeyId = this.operatorList.findIndex(x => x.fetch_slug === operator);
              if (operatorKeyId !== -1) {
                const operator = this.operatorList[operatorKeyId];
                this.updateOperator(operator['id']);
              }
            }
          })
          .catch(err => {
            console.error(err);
            if (operator === 'all') {
              this.fetchRunning = [];
            } else {
              this.fetchRunning.splice(this.fetchRunning.findIndex(x => x === operator), 1);
            }
          });
    },
    isRunning(operator) {
      return this.fetchRunning.findIndex(x => x === operator) === -1;
    },
    fetchInProgress() {
      return this.fetchRunning && this.fetchRunning.length > 0;
    },
    hasResults(operator) {
      return this.operatorResults.findIndex(x => x.name === operator) !== -1;
    },
    formatDate(date) {
      const format = Object.assign(DateTime.DATETIME_SHORT_WITH_SECONDS, {day: '2-digit', month: 'short'});
      return DateTime.fromSQL(date, {zone: 'UTC'}).toLocal().toLocaleString(format);
    },
    getResult(operator) {
      const operatorIndex = this.operatorResults.findIndex(x => x.name === operator);
      if (operatorIndex !== -1) {
        return this.operatorResults[operatorIndex];
      }
      return {
        chargers: {
          created: 0,
          updated: 0
        },
        connectors: {
          created: 0,
          updated: 0
        },
        locations: {
          created: 0,
          updated: 0
        },
        log: []
      };
    }
  }
}
</script>

<style scoped>

</style>
