<template>
  <div>
    <v-stepper v-model="stepper">
      <v-stepper-header>
        <v-stepper-step
            :complete="stepper > 1"
            step="1">
          Upload GPX file
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="stepper > 2"
            step="2">
          Load DB locations
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Compare locations
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-file-input
                    accept=".gpx"
                    v-model="gpxFile"
                    label="GPX file"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-btn @click="readGpxFile" color="secondary" elevation="2">Upload GPX file</v-btn>
                <v-btn class="ml-3" @click="resetData" color="error" elevation="2">Reset data</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-select
                    v-model="selectedOperator"
                    :items="operatorList"
                    item-text="name"
                    item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-btn @click="loadLocations" color="success" elevation="2">Load DB locations</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="resetStep">
              Back
            </v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card-text>
            <v-btn @click="compareLocations" color="success" elevation="2">Compare locations</v-btn>
            <v-btn class="ml-3" @click="resetData" color="error" elevation="2">Reset data</v-btn>
          </v-card-text>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-card class="mt-5" v-if="stepper === 3">
      <v-card-title>
      </v-card-title>
      <v-card-text v-if="distanceLocations">
        <v-data-table
            :headers="headers"
            :items="distanceLocations"
            @click:row="handleClickedRow"
            sort-by="distance"
            :items-per-page="15"
            :footer-props="{
              itemsPerPageOptions: [10,15,50,100,250,-1]
            }">
          <template v-slot:item.goingelectric="{ item }">
            <v-simple-checkbox
                v-ripple
                v-model="item.goingelectric"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.distance="{ item }">
            <span>{{ item.distance }} m</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="enableGoingElectric" color="warning" elevation="2">Set Goingelectric</v-btn>
        <v-btn @click="setGoingElectric" color="error" elevation="2">Update Goingelectric</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import GPX from 'gpx-parser-builder';

export default {
  name: "CsvCompare",
  data() {
    return {
      storageName: 'GPXLocations',
      stepper: 1,
      gpxInput: '',
      gpxFile: null,
      loadingOverlay: false,
      headers: [
        {text: 'Distance', value: 'distance'},
        {text: 'GPX Name', value: 'gpx_name'},
        {text: 'GPX Description', value: 'gpx_description'},
        {text: 'DB Name', value: 'db_name'},
        {text: 'DB Address', value: 'db_address'},
        {text: 'DB ID', value: 'db_id'},
        {text: 'Goingelectric', value: 'goingelectric'},
      ],
      operatorList: [],
      dbLocations: [],
      gpxLocations: [],
      distanceLocations: [],
      selectedOperator: ''
    }
  },
  mounted() {
    if (localStorage.getItem(this.storageName) !== null) {
      this.gpxInput = localStorage.getItem(this.storageName);
    }
    this.loadOperators();
  },
  methods: {
    resetData() {
      this.stepper = 1;
      this.gpxInput = '';
      this.gpxFile = null;
      this.loadingOverlay = false;
      this.dbLocations = [];
      this.gpxLocations = [];
      this.distanceLocations = [];
      this.selectedOperator = '';
      this.removeGpxLocations();
    },
    resetStep() {
      this.stepper = 1;
    },
    goToStep2() {
      this.stepper = 2;
    },
    goToStep3() {
      this.stepper = 3;
    },
    readGpxFile() {
      if (!this.gpxFile) {
        this.gpxInput = "No File Chosen"
      }
      let reader = new FileReader();

      // Use the javascript reader object to load the contents
      // of the file in the v-model prop
      reader.readAsText(this.gpxFile);
      reader.onload = () => {
        this.gpxInput = reader.result;
        this.saveGpxLocations();
        this.gpxToTable();
      }
    },
    saveGpxLocations() {
      localStorage.setItem(this.storageName, this.gpxInput);
    },
    removeGpxLocations() {
      localStorage.removeItem(this.storageName);
      this.gpxInput = '';
    },
    gpxToTable() {
      const gpx = GPX.parse(this.gpxInput);
      if (gpx !== null && gpx.wpt.length > 0) {
        gpx.wpt.forEach(waypoint => {
          this.gpxLocations.push({
            'latitude': waypoint.$.lat,
            'longitude': waypoint.$.lon,
            'name': waypoint.name,
            'description': waypoint.desc,
          });
        });
        this.goToStep2();
      }
    },
    compareLocations() {
      const distanceList = [];
      this.dbLocations.forEach((dbLocation) => {
        let shortestDistance = null;
        let closestName = '';
        let closestDescription = '';
        this.gpxLocations.forEach((gpxLocation) => {
          const distance = this.calculateDistance(gpxLocation.latitude, gpxLocation.longitude, dbLocation.latitude, dbLocation.longitude);

          if (shortestDistance === null || distance < shortestDistance) {
            shortestDistance = distance;
            closestName = gpxLocation.name;
            closestDescription = gpxLocation.description;
          }
        });
        distanceList.push({
          'distance': shortestDistance,
          'gpx_name': closestName,
          'gpx_description': closestDescription,
          'db_name': dbLocation.name,
          'db_address': dbLocation.street + ' ' + dbLocation.street_number + ', ' + dbLocation.zip + ', ' + dbLocation.city,
          'db_id': dbLocation.id,
          'goingelectric': false,
        });
      });
      this.distanceLocations = distanceList;

    },
    calculateDistance(a_lat, a_lng, b_lat, b_lng) {
      const unit = 'K';
      if ((a_lat === b_lat) && (a_lng === b_lng)) {
        return 0;
      } else {
        const radlat1 = Math.PI * a_lat / 180;
        const radlat2 = Math.PI * b_lat / 180;
        const theta = a_lng - b_lng;
        const radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
          dist = dist * 1.609344
        }
        if (unit === "N") {
          dist = dist * 0.8684
        }
        const distMeter = dist * 1000;

        return Math.round(distMeter * 10000) / 10000;
      }
    },
    handleClickedRow(value) {
      value.goingelectric = !value.goingelectric;
    },
    loadOperators() {
      this.$http.get('/items/operators?fields=id,name&filter[fetch_enabled]=1&sort=name')
          .then((resp) => {
            this.operatorList = resp.data.data;
          })
          .catch((err) => {
            console.error(err);
          })
    },
    loadLocations() {
      return this.$http.get('/items/locations?sort=-modified_on&limit=-1&fields=id,name,modified_on,goingelectric,street,street_number,city,zip,latitude,longitude&filter[goingelectric]=0&filter[ignored]=0&filter[operator.id]=' + this.selectedOperator)
          .then((resp) => {
            const data = resp.data.data;
            this.dbLocations = data;
            this.goToStep3();
            this.compareLocations();
          })
          .catch((err) => {
            console.error(err);
          })
    },
    enableGoingElectric() {
      const closest = this.distanceLocations
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 15);

      closest.forEach((location) => {
        location.goingelectric = true;
      });
    },
    setGoingElectric() {
      const promiseList = [];
      this.loadingOverlay = true;
      this.distanceLocations.forEach(location => {
        if (location.goingelectric) {
          promiseList.push(this.$http.patch('/items/locations/' + location.db_id, {goingelectric: true}));
        }
      });

      Promise.all(promiseList)
          .then(() => {
            this.loadingOverlay = false;
            return this.loadLocations();
          });
    },
  },
}
</script>

<style scoped>

</style>
