<template>
  <div>
    Dashboard
  </div>
</template>


<script>
export default {
  name: 'Dashboard',
  components: {
    // HelloWorld
  }
}
</script>
